import {
  BuildEnvironment,
  parseBooleanVariableOrFail,
  parseStringVariableOrFail,
} from 'project-conf'

export const IS_DEVELOPMENT_BUILD_ENV = process.env.BUILD_ENV === BuildEnvironment.development
export const IS_CI_BUILD_ENV = process.env.BUILD_ENV === BuildEnvironment.ci
export const IS_STAGING_BUILD_ENV = process.env.BUILD_ENV === BuildEnvironment.staging
export const IS_PRODUCTION_BUILD_ENV = process.env.BUILD_ENV === BuildEnvironment.production
export const BACKEND_API_URL = parseStringVariableOrFail(process.env, 'BACKEND_API_URL')
// eslint-disable-next-line max-len
export const BUILD_ENV = parseStringVariableOrFail(process.env, 'BUILD_ENV') as BuildEnvironment
export const DATADOG_APPLICATION_ID = parseStringVariableOrFail(
  process.env,
  'DATADOG_APPLICATION_ID'
)
export const DATADOG_CLIENT_TOKEN = parseStringVariableOrFail(process.env, 'DATADOG_CLIENT_TOKEN')
export const DATADOG_PROXY = parseStringVariableOrFail(process.env, 'DATADOG_PROXY')
export const FLAGSHIP_API_KEY = parseStringVariableOrFail(process.env, 'FLAGSHIP_API_KEY')
export const FLAGSHIP_ENVIRONMENT_ID = parseStringVariableOrFail(
  process.env,
  'FLAGSHIP_ENVIRONMENT_ID'
)
export const GTM_ID = parseStringVariableOrFail(process.env, 'GTM_ID')
export const IS_BUILD_OPTIMIZED = parseBooleanVariableOrFail(process.env, 'IS_BUILD_OPTIMIZED')
// eslint-disable-next-line max-len
export const IS_CROSS_DOMAIN_COOKIE_ENABLED = parseBooleanVariableOrFail(
  process.env,
  'IS_CROSS_DOMAIN_COOKIE_ENABLED'
)
export const IS_DATADOG_ENABLED = parseBooleanVariableOrFail(process.env, 'IS_DATADOG_ENABLED')
export const IS_DYNAMIC_FLAGS_FETCHING_ENABLED = parseBooleanVariableOrFail(
  process.env,
  'IS_DYNAMIC_FLAGS_FETCHING_ENABLED'
)
export const IS_PRIVATE_LOG_ENVIRONMENT = parseBooleanVariableOrFail(
  process.env,
  'IS_PRIVATE_LOG_ENVIRONMENT'
)
export const IS_FLAG_CHANGER_ENABLED = parseBooleanVariableOrFail(
  process.env,
  'IS_FLAG_CHANGER_ENABLED'
)
export const VERSION_ENV = parseStringVariableOrFail(process.env, 'VERSION_ENV')
