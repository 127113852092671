import { AppWithPorts, ElmTaggedType, PortFromElm } from 'DTSLib/MPArchitectureFoundations/types'

/**
 * onDOMReady promise
 *
 * @returns Promise resolved once DOM is ready
 */
export async function onDOMReady(): Promise<Event | void> {
  return new Promise((resolve) => {
    const isDomReady = document.readyState === 'interactive' || document.readyState === 'complete'
    if (isDomReady) resolve()
    else document.addEventListener('DOMContentLoaded', (evt) => resolve(evt))
  })
}

/**
 * onPageFullyLoaded promise
 *
 * @returns a  promise resolved once  the DOM have been fully parsed AND css/images have been fully loaded
 */
export async function onPageFullyLoaded(): Promise<Event | void> {
  return new Promise((resolve) => {
    const isDomLoaded = document.readyState === 'complete'
    if (isDomLoaded) resolve()
    else window.addEventListener('load', (evt) => resolve(evt))
  })
}

let scrollPosition = 0

/**
 * Locks page scroll
 */
export function lockPageScroll(): void {
  scrollPosition = window.scrollY
  document.documentElement.classList.add('scroll-locked')
}

/**
 * Unlocks page scroll
 */
export function unlockPageScroll(): void {
  document.documentElement.classList.remove('scroll-locked')
  window.scrollTo(0, scrollPosition)
}

interface DOMPortValue extends ElmTaggedType {
  type_: 'lock_page_scroll' | 'unlock_page_scroll'
}

/**
 * Mount a generic AppWithDomPort
 *
 * @param app - Elm App which implements the dom port
 */
export function mount(app: AppWithPorts<DOMPortInterface>): void {
  app.ports.dOMPort_.subscribe(dOMPort_)
}

/**
 * Gives Elm the access to these functions
 *
 * @param value - serialized log request from elm
 */
function dOMPort_(value: DOMPortValue): void {
  switch (value.type_) {
    case 'lock_page_scroll':
      lockPageScroll()
      break
    case 'unlock_page_scroll':
      unlockPageScroll()
      break
  }
}

export interface DOMPortInterface {
  dOMPort_: PortFromElm<DOMPortValue>
}
