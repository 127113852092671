import {
  AppWithPorts,
  ElmTaggedType,
  PortFromElm,
  PortInterface,
} from 'DTSLib/MPArchitectureFoundations/types'
import Logger from 'DTSLib/Logger'
import hash from 'object-hash'

/**
 * Global typings for window.gtag and window.dataLayer
 */
declare global {
  interface Window {
    _DEFAULT_CONSENT_HAS_BEEN_PUSHED?: boolean
    dataLayer: object[]
    gtag: Gtag.Gtag
  }
}

interface ConstructorParams {
  isGoogleConsentModeEnabled: boolean
  isPreRendering: boolean
  logger: Logger
  trackingId: string
}

/**
 * Holds in Gtag and data layer and exposes methods to send event through
 */
export class GoogleTagManager {
  private gtag: Gtag.Gtag
  private readonly trackingId: string
  private trackedOneShotHits: Set<string>
  private static SCRIPT_TAG_ID = 'googleTagManager'
  private readonly logger: Logger

  /**
   *  Constructor. Takes the gtag reference and inject
   *
   * @param args - a valid constructor param
   */
  public constructor(args: ConstructorParams) {
    this.trackingId = args.trackingId
    if (!args.isPreRendering) {
      this.loadGtagScript()
    }
    window.dataLayer = window.dataLayer || []
    window.gtag =
      window.gtag ||
      function (): void {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments)
      }
    this.gtag = window.gtag
    this.trackedOneShotHits = new Set()
    this.logger = args.logger

    if (args.isGoogleConsentModeEnabled) {
      if (window._DEFAULT_CONSENT_HAS_BEEN_PUSHED === undefined) {
        this.gtag('consent', 'default', {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line camelcase
          ad_personalization: 'denied',
          // eslint-disable-next-line camelcase
          ad_storage: 'denied',
          // eslint-disable-next-line camelcase
          ad_user_data: 'denied',
          // eslint-disable-next-line camelcase
          analytics_storage: 'granted',
          // eslint-disable-next-line camelcase
          functionality_storage: 'denied',
          // eslint-disable-next-line camelcase
          personalization_storage: 'denied',
          // eslint-disable-next-line camelcase
          security_storage: 'granted',
          // eslint-disable-next-line camelcase
          wait_for_update: 500,
        })
        this.gtag('set', 'ads_data_redaction', true)
        this.gtag('set', 'url_passthrough', true)
        window._DEFAULT_CONSENT_HAS_BEEN_PUSHED = true
      }
    }
    window.dataLayer.push({
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
    })
    this.gtag('js', new Date())
    this.gtag('config', this.trackingId)
  }

  /**
   * Adds a script tag in header and resolves gTag reference
   *
   */
  private loadGtagScript(): void {
    const script = document.createElement('script')
    script.setAttribute('id', GoogleTagManager.SCRIPT_TAG_ID)
    script.setAttribute('src', `https://www.googletagmanager.com/gtm.js?id=${this.trackingId}`)
    script.setAttribute('async', 'true')
    script.onload = (): void => {
      this.gtag = window.gtag
    }

    const preRenderedScriptTag = document.getElementById(GoogleTagManager.SCRIPT_TAG_ID)
    if (preRenderedScriptTag) {
      preRenderedScriptTag.replaceWith(script)
    } else {
      document.head.appendChild(script)
    }
  }

  /**
   * Wraps in a safe way GTM event tracking with debugging logs.
   *
   * @param eventName - a custom event name OR one of gtag predefined events labels
   * @param eventParams - Optional event parameters
   */
  private pushEvent(
    eventName: Gtag.EventNames | string,
    eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams
  ): void {
    this.raiseErrorIfLimitExceed({ eventName, eventParams })

    this.gtag('event', eventName, eventParams)
    const evt = {
      eventName,
      eventParams,
    }
    this.logger.devConsoleLog('GoogleTagManager:pushEvent', evt)
  }

  /**
   * Wraps in a safe way GTM event tracking with debugging logs.
   *
   * @param variable - a custom variable to push on data layer
   */
  private pushOnDataLayer(variable: IArguments): void {
    this.raiseErrorIfLimitExceed(variable)
    window.dataLayer.push(Object.assign({}, variable))
    this.logger.devConsoleLog('GoogleTagManager:pushOnDataLayer', variable)
  }

  /**
   * Checks if any of the pushed objects string values exceeds the maximum limit of 100 char imposed by Google
   *
   * @param object - Whatever you may want to push on GTM
   */
  private raiseErrorIfLimitExceed(object: object): void {
    const propertyStringExceeds = Object.values(object).some(
      (v) => typeof v == 'string' && new Blob([v]).size > 100
    )
    if (propertyStringExceeds) {
      this.logger.devConsoleError(
        'Maximum threshold for string property on GTM have been exceeded',
        object
      )
    }
  }

  /**
   * Mount Elm App
   *
   * @param app - Elm App which implements the GoogleTagManager port module
   */
  public mount(app: AppWithPorts<GoogleTagManagerPortInterface>): void {
    app.ports.googleTagManagerPort_.subscribe((portValue) => {
      switch (portValue.type_) {
        case 'push_on_data_layer': {
          if (portValue.oneShot) {
            if (!this.eventAlreadyTracked(portValue as OneShot)) {
              this.trackedOneShotHits.add(hash(portValue))
              this.pushOnDataLayer(portValue.variable)
              break
            } else {
              break
            }
          } else {
            this.pushOnDataLayer(portValue.variable)
            break
          }
        }
        case 'push_g_tag_event':
          if (portValue.oneShot) {
            if (!this.eventAlreadyTracked(portValue as OneShot)) {
              this.trackedOneShotHits.add(hash(portValue))
              this.pushEvent(portValue.eventName, portValue.eventParams)
              break
            } else {
              break
            }
          } else {
            this.pushEvent(portValue.eventName, portValue.eventParams)
            break
          }
      }
    })
  }

  /**
   * Checks whether the provided hit is already tracked in the current session
   *
   * @param  hit - the hit to check whether it's already been tracked
   * @returns whether the event has already been tracked
   */
  private eventAlreadyTracked<E extends OneShot>(hit: E): boolean {
    return this.trackedOneShotHits.has(hash(hit))
  }
}

/**
 * TS type version form Elm's GoogleTagManager port values
 */
export type GoogleTagManagerPortValue = PushGTagEvent | PushOnDataLayer // | Other future variants

export interface PushGTagEvent extends ElmTaggedType {
  eventName: string
  eventParams?: Gtag.CustomParams
  oneShot: boolean
  type_: 'push_g_tag_event'
}

export interface PushOnDataLayer extends ElmTaggedType {
  oneShot: boolean
  type_: 'push_on_data_layer'
  variable: IArguments
}

interface OneShot {
  oneShot: true
}

export interface GoogleTagManagerPortInterface extends PortInterface {
  googleTagManagerPort_: PortFromElm<GoogleTagManagerPortValue>
}
