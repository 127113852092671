import { v4 as uuidv4 } from 'uuid'
import Cookie from 'js-cookie'

/**
 * Tests again user agent string if it is associated to a known mobile device.
 * WARNING: this method may or may not work accordingly to browser settings as device fingerprinting is explicitly
 * hindered by tracking prevention policies
 *
 * @returns whether the user agent is associated to a mobile device
 */
export function isMobileUserAgent(): boolean {
  return /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
    navigator.userAgent
  )
}

/**
 * Pre-renderer user agent test. Use this for skip rendering of certain components if needed
 *
 * @returns whether the user agent is associated to our pre-renderer browser (see CI pipeline)
 */
export function isPreRendererUserAgent(): boolean {
  return /pre-renderer/.test(navigator.userAgent)
}

/**
 * Gets the browser temporary identifier stored in a cookie named wus_uuid.
 * This function gets current wus_uuid value, if not present for some unknown reason then a new value is generated and stored
 *
 * @param isCrossDomainCookieEnabled - whether cookie can be applied in cross-domain mode (env based)
 * @param crossDomain - the domain that will be used whe cross domain is enabled eg. '.prima.it'
 * @returns the uuid4 value
 */
export function getSessionIdentifier(
  isCrossDomainCookieEnabled: boolean,
  crossDomain: string
): string {
  const sessionIdentifierCookieName = 'wus_uid'
  let sessionIdentifier = Cookie.get(sessionIdentifierCookieName)

  if (!sessionIdentifier) {
    sessionIdentifier = uuidv4().toString()

    Cookie.set(sessionIdentifierCookieName, sessionIdentifier, {
      domain: isCrossDomainCookieEnabled ? crossDomain : undefined,
      sameSite: isCrossDomainCookieEnabled ? 'none' : 'lax',
      secure: isCrossDomainCookieEnabled,
    })
  }

  return sessionIdentifier
}

/**
 * Gets the browser sticky (1 year) identifier stored in a cookie named wu_uuid.
 * This function gets current wu_uuid value, if not present for some unknown reason then a new value is generated and stored
 *
 * @param isCrossDomainCookieEnabled - whether cookie can be applied in cross-domain mode (env based)
 * @param crossDomain - the domain that will be used whe cross domain is enabled eg. '.prima.it'
 * @returns the uuid4 value
 */
export function getPersistentIdentifier(
  isCrossDomainCookieEnabled: boolean,
  crossDomain: string
): string {
  const browserIdentifierCookieName = 'wu_uid'
  let browserIdentifier = Cookie.get(browserIdentifierCookieName)

  if (!browserIdentifier) {
    browserIdentifier = uuidv4().toString()

    const nextY = new Date()
    nextY.setFullYear(nextY.getFullYear() + 1)

    Cookie.set(browserIdentifierCookieName, browserIdentifier, {
      domain: isCrossDomainCookieEnabled ? crossDomain : undefined,
      expires: nextY,
      sameSite: isCrossDomainCookieEnabled ? 'none' : 'lax',
      secure: isCrossDomainCookieEnabled,
    })
  }

  return browserIdentifier
}
