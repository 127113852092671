import { AppWithPorts, ElmTaggedType, PortFromElm } from 'DTSLib/MPArchitectureFoundations/types'
import {
  EventCategory,
  Flagship,
  HitType,
  IHit,
  LogLevel,
  Visitor,
  primitive,
} from '@flagship.io/js-sdk'
import { IBucketingConfig } from '@flagship.io/js-sdk/dist/config/IBucketingConfig'
import { IDecisionApiConfig } from '@flagship.io/js-sdk/dist/config/IDecisionApiConfig'
import { IEdgeConfig } from '@flagship.io/js-sdk/dist/config/IEdgeConfig'
import Logger from 'DTSLib/Logger'

export type ConstructorArgs = {
  apiKey: string
  config?: IDecisionApiConfig | IBucketingConfig | IEdgeConfig
  envId: string
  logHitSending?: boolean
  logger: Logger
  visitorId: string
  visitorInitialContext?: Record<string, primitive>
}

export class FlagshipIO {
  private instance

  public visitorReadyPromise: Promise<Visitor>

  private readonly logHitSending: boolean

  private readonly logger: Logger

  /**
   * Wraps flagship start method in a class constructor which ensure to initialize a visitor
   *
   * @param args - constructor argument record
   */
  public constructor(args: ConstructorArgs) {
    this.instance = Flagship.start(args.envId, args.apiKey, {
      ...args.config,
      logLevel: LogLevel.WARNING,
      onBucketingFail: function (error) {
        args.logger.devConsoleLog('FlagshipIO:bucketing failure', error)
      },
      onBucketingSuccess: function ({ status, payload }): void {
        args.logger.devConsoleLog('FlagshipIO:bucketing success', status, payload)
      },
      onBucketingUpdated: function (lastUpdate) {
        args.logger.devConsoleLog('FlagshipIO:bucketing updated', lastUpdate)
      },
      onLog: (level, tag, message) => {
        args.logger.devConsoleLog('FlagshipIO:log', `[${LogLevel[level]}] [${tag}] : ${message}`)
      },
    })
    this.logHitSending = args.logHitSending || false
    this.logger = args.logger

    this.visitorReadyPromise = new Promise((resolve, reject) => {
      const v = this.instance.newVisitor({
        context: {
          ...args.visitorInitialContext,
          userAgent: navigator.userAgent,
        },
        hasConsented: true, // We consider a technical consent
        isAuthenticated: false,
        visitorId: args.visitorId,
      })

      v.on('ready', async (error) => {
        if (error) {
          args.logger.devConsoleLog('FlagshipIO:visitor ready error', error)
          reject(`Visitor ready error: ${error}`)
        }
        resolve(v)
      })
    })
  }

  /**
   * Fetch the flag from Flagship. Wraps the stuff into an async promise due to initial fetchFlags method.
   * Once the class have been correctly instanced with a visitor this method will never fail
   *
   * @param flagName - a flag name eg: "button-color"
   * @param fallbackValue - a fallback error returned in case of error eg: "red"
   * @returns a T value wrapped into flagship IFlag type
   */
  public async getFlag<T>(flagName: string, fallbackValue: T): Promise<T | unknown> {
    try {
      const v = await this.visitorReadyPromise

      return v.getFlag(flagName).getValue(fallbackValue, true)
    } catch (e) {
      this.logger.devConsoleLog('FlagshipIO:getFlag', e)

      return fallbackValue
    }
  }

  /**
   * Wraps internal flagship updateContext into a promise
   *
   * @param context -  a generic JSON object with no nesting
   */
  public async updateContext(context: Record<string, primitive>): Promise<void> {
    this.logger.devConsoleLog('FlagshipIO:updateContext', context)

    const v = await this.visitorReadyPromise
    v.updateContext(context)
    await v.fetchFlags()
  }

  /**
   * Wraps internal flagship send hit
   *
   * @param hit - flagship internal hit type
   */
  public async sendInternalHit(hit: IHit): Promise<void> {
    if (this.logHitSending) {
      console.log('FlagshipIO:sendInternalHit', hit)
    }
    const v = await this.visitorReadyPromise
    await v
      .sendHit(hit)
      .then(() => {
        if (this.logHitSending) {
          console.log('FlagshipIO:sendInternalHit sent')
        }
      })
      .catch((err) => {
        this.logger.devConsoleLog('FlagshipIO:sendInternalHit', err)
      })
  }

  /**
   * Wraps internal flagship send hits
   *
   * @param hits -  a list of flagship internal hit type
   */
  public async sendInternalHits(hits: IHit[]): Promise<void> {
    if (this.logHitSending) {
      console.log('FlagshipIO:sendInternalHits', hits)
    }
    const v = await this.visitorReadyPromise
    await v
      .sendHits(hits)
      .then(() => {
        if (this.logHitSending) {
          console.log('FlagshipIO:sendInternalHits sent')
        }
      })
      .catch((err) => {
        this.logger.devConsoleLog('FlagshipIO:sendInternalHits', err)
      })
  }

  /**
   * Mount elm App ports
   *
   * @param app - The App which implements FlagshipIO module
   */
  public mount(app: AppWithPorts<FlagshipIOPortInterface>): void {
    app.ports.flagshipIOPort_.subscribe((portValue) => {
      switch (portValue.type_) {
        case 'send_hit': {
          this.sendInternalHit(portValue.hit)
            .then()
            .catch((err) => {
              this.logger.devConsoleLog('FlagshipIO:flagshipIOPort_ send_hit', err)
            })
          break
        }
        case 'send_hits': {
          this.sendInternalHits(portValue.hits)
            .then()
            .catch((err) => {
              this.logger.devConsoleLog('FlagshipIO:flagshipIOPort_ send_hits', err)
            })
          break
        }
        case 'update_context': {
          this.updateContext(portValue.context)
            .then()
            .catch((err) => {
              this.logger.devConsoleLog('FlagshipIO:flagshipIOPort_ update_context', err)
            })
          break
        }

        default: {
          this.logger.devConsoleError(
            'FlagshipIO:flagshipIOPort_ unrecognized portValue type',
            portValue
          )
          break
        }
      }
    })
  }
}

export type FlagshipIOPortValue = SendHit | SendHits | UpdateContext

/**
 * Hit types supported by Elm interface
 */
export interface SendHit extends ElmTaggedType {
  hit: Hit
  type_: 'send_hit'
}

export type Hit = PageViewHit | EventHit

export interface SendHits extends ElmTaggedType {
  hits: Hit[]
  type_: 'send_hits'
}

export interface UpdateContext extends ElmTaggedType {
  context: Record<string, primitive>
  type_: 'update_context'
}
export interface PageViewHit {
  documentLocation: string
  type: HitType.PAGE_VIEW
}

export interface EventHit {
  action: string
  category: EventCategory
  label?: string
  type: HitType.EVENT
  value?: number
}

export interface FlagshipIOPortInterface {
  flagshipIOPort_: PortFromElm<FlagshipIOPortValue>
}
