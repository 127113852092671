import 'form-motor-it/theme'
import * as BrowserIdentification from 'DTSLib/BrowserIdentification'
import * as Env from 'FormMotorItLib/Env'
import { FlagshipIO } from 'DTSLib/Http/FlagshipIO'
import { GoogleTagManager } from 'DTSLib/Http/GoogleTagManager'
import { onDOMReady } from 'DTSLib/DOM'
import Logger from 'DTSLib/Logger'

init()

/**
 * Init function (let's avoid global scope pollution)
 */
function init(): void {
  /**
   * Let's start early with a non-blocking import of the application chunk
   */
  const appLoaderModulePromise = import('form-motor-it/AppLoader')

  const logger = new Logger({
    datadogConfiguration: {
      allowedTracingUrls: [
        {
          match: Env.BACKEND_API_URL,
          propagatorTypes: ['tracecontext'],
        },
      ],
      applicationId: Env.DATADOG_APPLICATION_ID,
      clientToken: Env.DATADOG_CLIENT_TOKEN,
      enableSessionTracking: false,
      env: Env.BUILD_ENV,
      isEnabled: Env.IS_DATADOG_ENABLED,
      proxy: Env.DATADOG_PROXY,
      service: 'dts-form-motor-it-fe',
      version: Env.VERSION_ENV,
    },
    isPrivateLogEnvironment: Env.IS_PRIVATE_LOG_ENVIRONMENT,
  })

  loadCacheServiceWorker(logger)
  backCacheReloadListener(logger)

  const flagshipIO = new FlagshipIO({
    apiKey: Env.FLAGSHIP_API_KEY,
    envId: Env.FLAGSHIP_ENVIRONMENT_ID,
    logHitSending: !Env.IS_PRIVATE_LOG_ENVIRONMENT,
    logger: logger,
    visitorId: BrowserIdentification.getPersistentIdentifier(
      Env.IS_CROSS_DOMAIN_COOKIE_ENABLED,
      '.prima.it'
    ),
    visitorInitialContext: { isMobile: BrowserIdentification.isMobileUserAgent() },
  })

  const gTM = new GoogleTagManager({
    isGoogleConsentModeEnabled: true,
    isPreRendering: BrowserIdentification.isPreRendererUserAgent(),
    logger: logger,
    trackingId: Env.GTM_ID,
  })

  /**
   * Before starting Elm application we need the application chunk resolved and DOM ready
   */
  Promise.all([appLoaderModulePromise, onDOMReady])
    .then(async ([appLoaderModule]) => {
      let flagsVal = {}
      if (Env.IS_DYNAMIC_FLAGS_FETCHING_ENABLED) {
        // Failure of the promise is handled in FlagshipIO
        flagsVal = (await flagshipIO.getFlag('dts-form-motor-it-fe-configuration', {})) ?? {}
      }

      logger.devConsoleLog('index.ts AppLoader initialization', 'Dynamic flags: ', flagsVal)
      new appLoaderModule.AppLoader({
        applicantIdentifier: BrowserIdentification.getPersistentIdentifier(
          Env.IS_CROSS_DOMAIN_COOKIE_ENABLED,
          '.prima.it'
        ),
        applicantSessionIdentifier: BrowserIdentification.getSessionIdentifier(
          Env.IS_CROSS_DOMAIN_COOKIE_ENABLED,
          '.prima.it'
        ),
        dynamicFlags: flagsVal,
        flagshipIOInstance: flagshipIO,
        googleTagManagerInstance: gTM,
        isMobile: BrowserIdentification.isMobileUserAgent(),
        isPreRendererBrowser: BrowserIdentification.isPreRendererUserAgent(),
        logger: logger,
        processEnv: process.env,
      })
    })
    .catch((err) => {
      logger.devConsoleError('index.ts:initial promise catastrophic error', err)

      // Desperate attempt to make it work again
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    })
}

/**
 * Loads cache service worker (aka Google Workbox).
 * service-worker.js is automatically built by webpack and automatically tracks outputted files with their build hash
 * Check https://webpack.js.org/guides/progressive-form-motor-itlication/ to learn more
 *
 * @param logger - logger instance
 */
function loadCacheServiceWorker(logger: Logger): void {
  const isEnabled = Env.IS_PRODUCTION_BUILD_ENV || Env.IS_STAGING_BUILD_ENV
  if ('serviceWorker' in navigator && isEnabled) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          logger.devConsoleLog('index.ts:loadCacheServiceWorker', registration)
        })
        .catch((registrationError) => {
          logger.devConsoleError('index.ts:loadCacheServiceWorker', registrationError)
        })
    })
  }

  if (!isEnabled) {
    logger.devConsoleLog(
      'index.ts:loadCacheServiceWorker',
      'Service worker is not enabled outside production and staging environment'
    )
  }
}

/**
 * Forces page reload in case of cross-domain back click.
 * In some browser the application state cache is resumed from the very last moment in some others no,
 * this will fix the "show loader" on some browser when you're navigating outside the SPA scope and then go back
 *
 * @param logger - logger instance
 */
function backCacheReloadListener(logger: Logger): void {
  window.onpageshow = function (event): void {
    if (event.persisted) {
      logger.devConsoleLog('Back cache reload detected')
      window.location.reload()
    }
  }
}
