import {
  AppWithPorts,
  ElmTaggedType,
  PortBinderClass,
  PortFromElm,
} from 'DTSLib/MPArchitectureFoundations/types'
import { MatchOption } from '@datadog/browser-core'
import { TracingOption } from '@datadog/browser-rum-core/src/domain/tracing/tracer.types'
import { datadogRum } from '@datadog/browser-rum'

type DatadogConf = {
  allowedTracingUrls: Array<MatchOption | TracingOption> | undefined
  applicationId: string
  clientToken: string
  enableSessionTracking: boolean
  env: string
  isEnabled: boolean
  proxy: string | null | undefined
  service: string
  version: string
}

type Conf = {
  datadogConfiguration: DatadogConf
  isPrivateLogEnvironment: boolean
}

export default class Logger implements PortBinderClass<LoggerPortInterface> {
  private readonly isPrivateLogEnvironment: boolean

  /**
   * Constructor for log instance
   *
   * @param args - constuctor arguments
   */
  public constructor(args: Conf) {
    bootDatadog(args.datadogConfiguration)
    this.isPrivateLogEnvironment = args.isPrivateLogEnvironment
  }

  /**
   * Mounts logger ports onto an Elm app
   *
   * @param app - Elm app instance which uses Logger port module
   */
  public mount(app: AppWithPorts<LoggerPortInterface>): void {
    app.ports.loggerPort_.subscribe((value) => loggerPort_(this, value))
  }

  /**
   * `console.log` wrapper, logs only when environment is not Production and not Staging
   *
   * @param codeLocation - where in your code this log has been emitted e.g. "Utils:devConsoleLog"
   * @param args - object to log
   */
  public devConsoleLog(codeLocation: string, ...args: any[]): void {
    if (!this.isPrivateLogEnvironment) {
      console.log(`Log @${codeLocation}: `, ...args)
    }
  }

  /**
   * `console.log` wrapper, logs only when environment is not Production and not Staging
   *
   * @param codeLocation - where in your code this error has been emitted e.g. "Utils:devConsoleLog"
   * @param args - object to log
   */
  public devConsoleWarn(codeLocation: string, ...args: any[]): void {
    if (!this.isPrivateLogEnvironment) {
      console.warn(`Warn @${codeLocation}: `, ...args)
    }
  }

  /**
   * `console.log` wrapper, logs only when environment is not Production and not Staging
   *
   * @param codeLocation - where in your code this error has been emitted e.g. "Utils:devConsoleLog"
   * @param args - object to log
   */
  public devConsoleError(codeLocation: string, ...args: any[]): void {
    datadogRum.onReady(() => {
      datadogRum.addError(`Error @${codeLocation}`, args)
    })
    if (!this.isPrivateLogEnvironment) {
      console.error(`Error @${codeLocation}: `, ...args)
    }
  }
}

/**
 * Boots datadog logger as side effect
 *
 * @param conf - configuration object
 */
function bootDatadog(conf: DatadogConf): void {
  if (conf.isEnabled) {
    datadogRum.init({
      allowedTracingUrls: conf.allowedTracingUrls,
      applicationId: conf.applicationId,
      clientToken: conf.clientToken,
      defaultPrivacyLevel: 'mask',
      env: conf.env,
      proxy: conf.proxy ?? undefined,
      service: conf.service,
      sessionReplaySampleRate: 20,
      sessionSampleRate: 100,
      site: 'datadoghq.eu',
      trackLongTasks: true,
      trackResources: true,
      trackUserInteractions: true,
      version: conf.version,
    })

    if (conf.enableSessionTracking) {
      datadogRum.startSessionReplayRecording()
    }
  }
}

interface LogInterface extends ElmTaggedType {
  args: object
  codeLocation: string
  type_: 'log' | 'warn' | 'error'
}

export interface Log extends LogInterface {
  type_: 'log'
}

export interface Warn extends LogInterface {
  type_: 'warn'
}
export interface Error extends LogInterface {
  type_: 'error'
}

export type LoggerPortValue = Log | Warn | Error

export interface LoggerPortInterface {
  loggerPort_: PortFromElm<LoggerPortValue>
}

/**
 * Port listener
 *
 * @param logger - logger instance
 * @param log - port value
 */
function loggerPort_(logger: Logger, log: LoggerPortValue): void {
  switch (log.type_) {
    case 'error':
      logger.devConsoleError(log.codeLocation, log.args)
      break
    case 'warn':
      logger.devConsoleWarn(log.codeLocation, log.args)
      break
    case 'log':
      logger.devConsoleLog(log.codeLocation, log.args)
      break
  }
}
