import { SpawnOptionsWithoutStdio, spawn } from 'child_process'

/**
 * Prints a console warn message with a yellow WARNING title before
 *
 * @param title - the title to print in Yellow
 * @param optionalParams - the following optionalParams to send to console
 */
export function prettyWarn(title: string, ...optionalParams: any[]): void {
  console.warn(`\n\x1b[43mWARNING:${title}\x1b[0m`, ...optionalParams)
}

/**
 * Prints a console error message with a red ERROR title before
 *
 * @param title - the title to print in Red
 * @param optionalParams  - the following optionalParams to send to console
 */
export function prettyError(title: string, ...optionalParams: any[]): void {
  console.error(`\n\x1b[41mERROR:${title}\x1b[0m`, ...optionalParams)
}

/**
 * Prints a console log message with a cyan INFO title before
 *
 * @param title - the title to print in Cyan
 * @param optionalParams - the following optionalParams to send to console
 */
export function prettyInfo(title: string, ...optionalParams: any[]): void {
  console.info(`\n\x1b[48;5;2m${title}\x1b[0m`, ...optionalParams)
}

/**
 * Spawns a child process and fails the promise if an error is detected
 * - process and child stdin/stdout are piped together
 * - intercepted exit with status code = 0 resolves the promise
 * - intercepted exit with status code != 0 fails the promise
 * - intercepted error fails the promise
 *
 * @param logMsg - What to pretty print for such step
 * @param command - The command program name eg: "yarn webpack" -\> "yarn"
 * @param args - The command following arguments "yarn webpack --mode "DEV"" -\> ["webpack", "--mode", "DEV"]
 * @param spawnOptions - spawn command options
 * @returns - a promise with the emitted messages in the terminal by the process
 */
export async function spawnCommand(
  logMsg: string,
  command: string,
  args?: ReadonlyArray<string>,
  spawnOptions?: SpawnOptionsWithoutStdio
): Promise<void> {
  prettyInfo('> Running:', logMsg)

  return new Promise<void>((resolve, reject) => {
    const spawnProcess = spawn(command, args, spawnOptions)

    // Ties together cross pipe current process input -> spawn process input
    process.stdin.pipe(spawnProcess.stdin)
    // Ties together spawn process output -> Current process output
    spawnProcess.stdout.pipe(process.stdout)

    spawnProcess.stderr.pipe(process.stderr)

    spawnProcess.on('error', (error: Error) => {
      reject(error)
    })
    spawnProcess.on('close', (code: number) => {
      if (code === 0) {
        resolve()
      } else {
        reject(`Spawned process exited with non zero code: ${code}`)
      }
    })
  })
}

/**
 * Sleep Promise
 *
 * @param ms - millis to sleep
 * @returns a resolved promise after given millis
 */
export async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}
